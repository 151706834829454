import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import useAxios from "../hooks/useAxios";
import useErrorHandler from "../hooks/useErrorHandler";

import { AuthConsumer } from "./AuthProvider";
import { isDateBeforeYearsAgo, filterLastThreads } from "../helpers/functions";

const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL || "";
const KYC_ENABLED = import.meta.env.VITE_KYC_ENABLED === "true";
const KYC_MAX_ATTEMPTS = 15; // try for 30 secs

const DashboardCtxt = createContext(null);

const DashboardProvider = ({ children }) => {
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const auth = AuthConsumer();
  const { initAxios } = useAxios();

  const [searchParams] = useSearchParams();
  const kycDoneRedirect = searchParams.get("redirect") === "fair-kyc";

  const { user, elevatedUser } = auth ? auth : null;
  const isBackofficeUser = user?.Role === "Backoffice" ? true : false;
  const isAdminUser = user?.Role === "Admin" ? true : false;
  const isCustomer = !(isAdminUser || isBackofficeUser) ? true : false;
  const isImpersonatedBy = elevatedUser ? elevatedUser.Role : false;
  const poolingInterval = useRef(0);
  const pooling_attempts = useRef(0);
  const axiosKycRequestController = useRef(null);

  let isKycRequired = false;
  if (
    KYC_ENABLED &&
    user.NoKycNeeded !== "1" && // do not show if NoKycNeeded
    user.KycCompleted && // do not show if Kyc completed date is null
    ((elevatedUser &&
      (elevatedUser.Role === "Poa" || elevatedUser.Role === "Customer")) ||
      (!elevatedUser && user.Role === "Customer")) // only show for customers and POAs
  ) {
    isKycRequired = isDateBeforeYearsAgo(user.KycCompleted, 2);
  }

  const [state, setState] = useState({
    loaded: false,
    isBackofficeUser,
    isAdminUser,
    isCustomer,
    isImpersonatedBy,
    isKycRequired
  });

  const isProduction = FRONTEND_URL === "https:/kundportal.fairinvestments.se";

  const checkKyc = useCallback(async () => {
    console.log("KYC :: Retrieving status");
    pooling_attempts.current += 1;

    if (pooling_attempts.current > KYC_MAX_ATTEMPTS) {
      // if 30 secs have passed, stop trying
      clearInterval(poolingInterval.current);
      axiosKycRequestController.current.abort();
      navigate("/");
      return window.location.reload();
    }

    try {
      const { axiosInstance, axiosController } = initAxios("private");
      axiosKycRequestController.current = axiosController;
      const { data } = await axiosInstance.get("user/kyc");
      const kycCompletedDate = data;

      let isKycRequired = false;
      if (kycCompletedDate) {
        isKycRequired = isDateBeforeYearsAgo(kycCompletedDate, 2);
      }

      if (!isKycRequired) {
        clearInterval(poolingInterval.current);
        setState(prevState => ({
          ...prevState,
          isKycRequired
        }));
        navigate("/");
        return window.location.reload();
      }
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [initAxios, navigate, errorHandler]);

  const startPoolingKyc = useCallback(() => {
    if (poolingInterval.current) {
      clearInterval(poolingInterval.current);
    }
    poolingInterval.current = setInterval(checkKyc, 2000);
  }, [checkKyc]);

  const fetchCustomerData = useCallback(
    axios => {
      const requests = [
        {
          id: "accountsSummary",
          promise: axios.get("/account/all/summary/owner")
        },
        {
          id: "messages",
          promise: axios.get("/messages/received")
        },
        {
          id: "notifications",
          promise: axios.get("/notifications")
        },
        {
          id: "advisors",
          promise: axios.get("/user/advisors")
        },
        {
          id: "rssFeed",
          promise: axios.get("/rssfeed")
        }
      ];

      // Create an array of just the promises
      const promises = requests.map(req => req.promise);

      try {
        Promise.all(promises)
          .then(responses => {
            // Map responses to their corresponding identifiers
            const results = requests.map((req, index) => ({
              id: req.id,
              response: responses[index]
            }));

            // Map results to an object with the identifiers as keys
            const mappedResults = results.reduce((acc, { id, response }) => {
              acc[id] = response.data;
              return acc;
            }, {});

            let {
              accountsSummary,
              messages,
              notifications,
              advisors,
              rssFeed
            } = mappedResults;

            setState(prevState => ({
              ...prevState,
              loaded: true,
              accountsSummary,
              messages: filterLastThreads(messages),
              notifications,
              advisors,
              rssFeed
            }));
          })
          .catch(function (err) {
            errorHandler.serverError(err);
          });
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [errorHandler]
  );

  const fetchElevatedUserData = useCallback(
    axios => {
      const requests = [];

      try {
        // Accounts with Total Market Value data request
        const requestAllCustomers = axios.get("/user/customers");
        requests.push(requestAllCustomers);

        if (isAdminUser) {
          const requestAllBackofficeUsers = axios.get("/user/backoffice/list");
          requests.push(requestAllBackofficeUsers);
        }

        // Get Messages
        const requestMessages = axios.get("/messages/received");
        requests.push(requestMessages);

        // Get Notifications
        const requestNotifications = axios.get("notifications");
        requests.push(requestNotifications);

        // Get RssFeed
        const requestRssFeed = axios.get("/rssfeed");
        requests.push(requestRssFeed);

        Promise.all(requests)
          .then(responses => {
            const customers = responses[0].data;
            const backofficeUsers = isAdminUser ? responses[1].data : null;
            const messages = isAdminUser
              ? responses[2].data
              : responses[1].data;
            const notifications = isAdminUser
              ? responses[3].data
              : responses[2].data;
            const rssFeed = isAdminUser ? responses[4].data : responses[3].data;

            setState(prevState => ({
              ...prevState,
              loaded: true,
              customers,
              ...(isAdminUser && { backofficeUsers }),
              messages: filterLastThreads(messages),
              notifications,
              rssFeed
            }));
          })
          .catch(function (err) {
            errorHandler.serverError(err);
          });
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [errorHandler, isAdminUser]
  );

  useEffect(() => {
    const { axiosInstance, axiosController } = initAxios("private");
    if (kycDoneRedirect) {
      return startPoolingKyc();
    }

    if (!state.loaded && !state.isKycRequired) {
      if (isCustomer) fetchCustomerData(axiosInstance);
      if (isBackofficeUser || isAdminUser) fetchElevatedUserData(axiosInstance);
    }

    return () => axiosController.abort();
  }, [
    kycDoneRedirect,
    startPoolingKyc,
    fetchCustomerData,
    fetchElevatedUserData,
    initAxios,
    isAdminUser,
    isBackofficeUser,
    isCustomer,
    state.loaded,
    state.isKycRequired
  ]);

  const contextValue = useMemo(
    () => ({
      isProduction,
      state,
      setState,
      kycDoneRedirect
    }),
    [isProduction, state, kycDoneRedirect]
  );

  return (
    <DashboardCtxt.Provider value={contextValue}>
      {children}
    </DashboardCtxt.Provider>
  );
};

export const DashboardConsumer = () => {
  return useContext(DashboardCtxt);
};

export default DashboardProvider;

DashboardProvider.propTypes = {
  children: PropTypes.node
};
