import axios from "axios";
import { TimeOutConsumer } from "../contexts/TimeOutProvider";
import { delay } from "../helpers/functions";

const AXIOS_TIMEOUT = import.meta.env.VITE_AXIOS_TIMEOUT || 30000;
const NETWORK_RETRY_INTERVAL = 5000;
const NETWORK_MAX_RETRY_ATTEMPTS = 12;

const useAxios = () => {
  const authTimeOut = TimeOutConsumer();

  const initAxios = instanceType => {
    const axiosController = new AbortController();

    const requiresCreds = instanceType === "private" || instanceType === "auth";

    const axiosConfig = {
      baseURL: import.meta.env.VITE_BACKEND_API_URL,
      params: {
        t: new Date().getTime()
      },
      timeout: AXIOS_TIMEOUT,
      signal: axiosController.signal,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded" // Code ingniter post setting
        // "Content-Type": "application/json"
      },
      withCredentials: requiresCreds // required for getting cookies from server!
    };

    const axiosInstance = axios.create(axiosConfig);

    if (instanceType === "private") {
      axiosInstance.interceptors.response.use(response => {
        authTimeOut.softResetTimeOut();
        return response;
      });
    }

    axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;
        if (prevRequest && !prevRequest.tries) prevRequest.tries = 0;

        if (
          error?.code === "ERR_NETWORK" &&
          prevRequest?.tries <= NETWORK_MAX_RETRY_ATTEMPTS
        ) {
          prevRequest.tries++;
          await delay(NETWORK_RETRY_INTERVAL); // delay function allows us to use setTimeout inside a promise

          console.log(error, "Network error, retrying request");
          return axiosInstance(prevRequest);
        }

        return Promise.reject(error);
      }
    );

    return { axiosInstance, axiosController };
  };

  return {
    initAxios
  };
};

export default useAxios;
