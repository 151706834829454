import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairButton from "../../../theme/styled/FairButton";

const ContactModal = props => {
  const { state, onClose, onSubmission } = props;

  const { status, message } = state;

  return (
    <Modal open={true}>
      <FairModalBox sx={{ width: 300, height: 300 }}>
        <Box
          sx={{
            height: 50,
            px: 4,
            pb: 5
          }}
        >
          <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
            Kontaktförfrågan
          </Typography>
        </Box>
        {status === "sending" ? (
          <Box
            sx={{
              height: 135,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <CircularProgress />
            <br />
            <Typography color="black">Var god vänta... </Typography>
          </Box>
        ) : (
          <>
            {status === "sent" ? (
              <>
                <Box
                  sx={{
                    height: 135,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexFlow: "column",
                    px: 4
                  }}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    color={status === "error" ? "error.main" : "black.common"}
                    sx={{ mb: 0 }}
                  >
                    {message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexFlow: "column",
                    px: 4
                  }}
                >
                  <div>
                    <Button
                      fullWidth
                      onClick={onClose}
                      sx={{
                        color: "primary.lighter",
                        my: 1
                      }}
                    >
                      Stäng
                    </Button>
                  </div>
                </Box>
              </>
            ) : (
              <Box
                component="form"
                onSubmit={onSubmission}
                sx={{
                  height: 235,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexFlow: "column",
                  px: 4
                }}
              >
                <Typography variant="subtitle1" color="common.black">
                  Vill du bli kontaktad av din rådgivare?
                </Typography>
                <FairButton
                  type="submit"
                  sx={{
                    mt: 6,
                    mb: 1,
                    color: "primary.main"
                  }}
                >
                  Ja
                </FairButton>
                <Button
                  onClick={onClose}
                  sx={{
                    my: 1,
                    color: "primary.lighter"
                  }}
                >
                  Nej
                </Button>
              </Box>
            )}
          </>
        )}
      </FairModalBox>
    </Modal>
  );
};

ContactModal.propTypes = {
  selectedCard: PropTypes.string,
  state: PropTypes.object,
  onClose: PropTypes.func,
  onSubmission: PropTypes.func
};

export default ContactModal;
